import { Typography } from "@mui/material";
import Container from "@mui/material/Container";
import * as React from "react";
import { Helmet } from "react-helmet";
import Layout from "src/components/Layout";

const LegacyPage = () => {
  React.useEffect(() => {
    window.location =
      "https://www.paypal.com/donate/?hosted_button_id=G93HRCX7NCCL6";
  }, []);

  return (
    <Layout>
      <Container sx={{ py: 10 }}>
        <Helmet>
          <title>Wendy Jackson Legacy Fund</title>
        </Helmet>
        <Typography>
          Please donate at{" "}
          <a href="https://www.paypal.com/donate/?hosted_button_id=G93HRCX7NCCL6">
            PayPal
          </a>
          . Thank you.
        </Typography>
      </Container>
    </Layout>
  );
};

export default LegacyPage;
